import NotFound from "@/layouts/NotFound.vue";
import alertRoutes from "@/pages/alerts/routes";
import conditionsRoutes from "@/pages/conditions/routes";
import memberRoutes from "@/pages/member/routes";
import marketsRoutes from "@/pages/markets/routes";
import ordersRoutes from "@/pages/orders/routes";
import settingsRoutes from "@/pages/settings/routes";
import opsRoutes from "@/pages/ops/routes";
import publicRoutes from "@/pages/public/routes";
import cryptoGames from "@/pages/game/routes";
import backtesting from "@/pages/backtest/routes";
import journaling from "@/pages/journal/routes";
import store from "@/store";

export const routes = [
    {
        path: '/',
        name: 'LandingLayout',
        component: () => import("@/layouts/Landing.vue"),
        children: [
            ...publicRoutes.routes,
        ]
    },
    {
        path: '/app',
        name: 'MembersLayout',
        component: () => import("@/layouts/Member.vue"),
        children: [
            ...memberRoutes.routes,
            ...alertRoutes.routes,
            ...conditionsRoutes.routes,
            ...settingsRoutes.routes,
            ...marketsRoutes.routes,
            ...ordersRoutes.routes,
            ...cryptoGames.routes,
            ...backtesting.routes,
            ...journaling.routes,
        ]
    },
    {
        path: '/ops',
        name: 'OpsLayout',
        component: () => import("@/layouts/Member.vue"),
        children: [
            ...opsRoutes.routes,
        ],
        async beforeEnter (to, from, next){
            if (from.path === '/'){ //wait for backgrounds stuff to fetch the user info as this is probably a refresh
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
            if (store.state.app.user === null || store.state.app.user.id === ''){
                next('/login');
            }else if (store.state.app.user.ops === 'u') {
                next('/app');
            } else {
                next();
            }
        }
    },
    { path: '/*', component: NotFound }
];
