import axios from "axios";

let axi = axios.create({
    baseURL: '/'
})




let api = {
    alert: {
        get: (then, catcher, alertId) => {
            get(`/alert/v1/${alertId}`, then, catcher)
        },
        chart: {
            config: (then, catcher, alertId) => {
                get(`/alert/v1/${alertId}/chart/config`, then, catcher)
            },
        },
        update: (then, catcher, alertId, payload) => {
            post(`/alert/v1/${alertId}`, payload, then, catcher)
        },
        clone: (then, catcher, alertId) => {
            get(`/alert/v1/${alertId}/clone`,  then, catcher)
        },
        list: (then, catcher, pagination) => {
            get(`/alert/v1/list?page=${pagination.page}&items=${pagination.items}&term=${pagination.term}`, then, catcher)
        },
        listSymbol: {
            conditions: (then, catcher, symbol) => {
                get(`/alert/v1/list/symbol/${symbol}/conditions`, then, catcher)
            }
        },
        create: (then, catcher, payload) => {
            post(`/alert/v1/list`, payload, then, catcher)
        },
        delete: (then, catcher, alertId) => {
            del(`/alert/v1/${alertId}`, then, catcher)
        },
        public:{
            list:(then, catcher, page, perPage, title, username) => {
                get(`/alert/v1/public/list?page=${page}&per_page=${perPage}&search=${title}&user=${username}`, then, catcher)
            }
        },
        ops: {
            userAlerts:(then, catcher, userId) => {
                get(`/alert/ops/user/${userId}/alerts`, then, catcher)
            },
            find:(then, catcher, search) => {
                let query = "?search="
                if (search.term !== ""){
                    query += search.term
                }
                if (Array.isArray(search.id)) {
                    query = query + "&" + search.id.map(v => `id=${encodeURIComponent(v)}`).join('&');
                }
                get(`/alert/ops/find?${query}`, then, catcher)
            },
        },
    },
    auth: {
        login: (then, catcher, loginData) => {
            axi.post("/auth/public/login", loginData).then(then).catch(catcher)
        },
        logout: (then, catcher) => {
            post("/auth/v1/user/logout", {}, then, catcher)
        },
        register: (then, catcher, registerData) => {
            post("/auth/public/register", registerData, then, catcher)
        },
        confirmRegister: (then, catcher, code) => {
            post("/auth/public/register/confirm", {code: code}, then, catcher)
        },
        reset: (then, catcher, payload) => {
            post("/auth/public/reset", payload, then, catcher)
        },
        resetConfirm: (then, catcher, payload) => {
            post("/auth/public/reset/confirm", payload, then, catcher)
        },
        refresh: (then, catcher, refreshData) => {
            post("/auth/public/refresh", refreshData, then, catcher)
        },
        user: (then, catcher) => {
            get("/auth/v1/user", then, catcher, true)
        },
        public:{
            profiles: (then, catcher, userIds) => {
                get(`/auth/public/profiles?&${userIds.map(value => `ids=${encodeURIComponent(value)}`).join('&')}`, then, catcher)
            },
            googleLogin: (then, catcher) => {
                get(`/auth/public/google/login`, then, catcher)
            }
        },
        ops: {
            users: (then, catcher, term) => {
                get(`/auth/ops/users?email=${term}`, then, catcher)
            },
            user:(then, catcher, userId) => {
                get(`/auth/ops/user/${userId}`, then, catcher)
            },
        }
    },
    bill: {
        plans: (then, catcher) => {
            get(`/bill/v1/plans`, then, catcher)
        },
        user: {
            subscribe: {
                stripe: (then, catcher, planType) => {
                    get(`/bill/v1/user/subscribe/stripe?opt_plan=${planType}`, then, catcher)
                },
            },
            invoices: (then, catcher) => {
                get(`/bill/v1/user/invoices`, then, catcher)
            },
            upgrade: (then, catcher, fromPlan, toPlan) => {
                post(`/bill/v1/user/upgrade`, {
                    from_type: fromPlan,
                    to_type: toPlan,
                }, then, catcher)
            },
            code: (then, catcher, code) => {
                post(`/bill/v1/user/code`, {code:code},then, catcher)
            },
            redeem: (then, catcher, code) => {
                post(`/bill/v1/user/redeem`, {code:code},then, catcher)
            },
        },
        ops: {
            userSubscriptions:(then, catcher, userId) => {
                get(`/bill/ops/user/${userId}/subscriptions`, then, catcher)
            },
            addUserSubscription:(then, catcher, payload) => {
                post(`/bill/ops/user/${payload.user_id}/subscriptions`, payload, then, catcher)
            },
            deleteSubscription:(then, catcher, subscriptionId) => {
                del(`/bill/ops/subscription/${subscriptionId}`,  then, catcher)
            },
            codes:{
                create:(then, catcher, partner_name, prefix, length, amount, plan_type, duration_days) => {
                    post(`/bill/ops/codes`, {
                        partner_name:partner_name,
                        prefix:prefix,
                        length:parseInt(length),
                        amount:parseInt(amount),
                        plan_type:plan_type,
                        duration_days:parseInt(duration_days),
                    },  then, catcher)
                },
                stats:(then, catcher) => {
                    get(`/bill/ops/codes/stats`, then, catcher)
                },
                list:(then, catcher, partner_name, plan_type, available_only) => {
                    get(`/bill/ops/codes/list?partner_name=${partner_name}&plan_type=${plan_type}&available_only=${available_only===true?'yes':'no'}`, then, catcher)
                },
            },
        }

    },
    favourite: {
        condition:{
            list: (then, catcher) => {
                get(`/favourite/v1/condition/list`, then, catcher)
            },
            add: (then, catcher, condition_id) => {
                post(`/favourite/v1/condition/list`, {condition_id:condition_id},  then, catcher)
            },
            del: (then, catcher, id) => {
                del(`/favourite/v1/condition/${id}`, then, catcher)
            },
        },
        ticker:{
            list: (then, catcher) => {
                get(`/favourite/v1/ticker/list`, then, catcher)
            },
            add: (then, catcher, exchange, ticker) => {
                post(`/favourite/v1/ticker/list`, {exchange:exchange, ticker:ticker},  then, catcher)
            },
            del: (then, catcher, exchange, ticker) => {
                del(`/favourite/v1/ticker/${exchange}.${ticker}`, then, catcher)
            },
        },
    },
    game: {
        launch: (then, catcher) => {
            get(`/game/v1/`, then, catcher)
        },
        skip: (then, catcher, game_id) => {
            post(`/game/v1/skip`, {game_id: game_id}, then, catcher)
        },
        play: (then, catcher, req) => {
            post(`/game/v1/play`, req, then, catcher)
        },
        history: (then, catcher, page, perPage) => {
            get(`/game/v1/history?page=${page}&per_page=${perPage}`, then, catcher)
        },
        stats: (then, catcher) => {
            get(`/game/v1/stats`, then, catcher)
        },
        leaderboard: (then, catcher) => {
            get(`/game/v1/leaderboard`, then, catcher)
        },
    },
    kline: {
        get: (then, catcher, exchange, ticker, interval) => {
            get(`/kline/v1?exchange=${exchange}&ticker=${ticker}&interval=${interval}`, then, catcher)
        },
        indicator: (then, catcher, exchange, ticker, interval, indicators) => {
            let indQuery = indicators.map(function (indicator) {
                return "name=" + indicator;
            }).join("&");
            get(`/kline/v1/indicator?exchange=${exchange}&ticker=${ticker}&interval=${interval}&${indQuery}`, then, catcher)
        },
        orderbook: (then, catcher, exchange, ticker) => {
            get(`/kline/v1/orderbook?exchange=${exchange}ticker=${ticker}`, then, catcher)
        },
        conditions: {
            list: (then, catcher, pagination) => {
                get(`/kline/v1/conditions?page=${pagination.page}&per_page=${pagination.per_page}&search=${pagination.search}&` +
                    `input1_element=${pagination.input1_element}&input1_type=${pagination.input1_type}&` +
                    `op=${pagination.op}&` +
                    `input2_element=${pagination.input2_element}&input2_type=${pagination.input2_type}&`, then, catcher)
            },
            listIds: (then, catcher, ids) => {

                get(`/kline/v1/conditions?&${ids.map(value => `ids=${encodeURIComponent(value)}`).join('&')}`, then, catcher)
            },
            create: (then, catcher, payload) => {
                post(`/kline/v1/conditions`, payload, then, catcher)
            },
            find: (then, catcher, payload) => {
                post(`/kline/v1/conditions/find`, payload, then, catcher)
            },
            sample: (then, catcher) => {
                get(`/kline/v1/conditions/sample`, then, catcher)
            },
        },
        ops:{
            condition: {
                del:(then, catcher, id) => {
                    del(`/kline/ops/condition/${id}`, then, catcher)
                }
            },
        },
    },
    market: {
        config: (then, catcher) => {
            get(`/market/v1/config`, then, catcher)
        },
        liquidity: (then, catcher) => {
            get(`/market/v1/liquidity`, then, catcher)
        },
        list:{
            tickers: (then, catcher) => {
                get(`/market/v1/list/ticker`, then, catcher)
            },
        },
        ticker:{
            conditions: (then, catcher, exchange, ticker) => {
                get(`/market/v1/ticker/${exchange}/${ticker}/conditions`, then, catcher)
            },
        },
    },
    strategy:{
        get: (then, catcher, id) => {
            get(`/strategy/v1/${id}`, then, catcher)
        },
        update: (then, catcher, strategy) => {
            // {
            //     id: "",
            //     alert_id:"",
            //     name:"",
            //     position_type:"",
            //     sl:"",
            //     tp:"",
            //     balance: 0.0,
            //     position:"",
            // }
            strategy.balance = parseFloat(strategy.balance)
            strategy.margin = parseFloat(strategy.margin)
            post(`/strategy/v1/${strategy.id}`, strategy, then, catcher)
        },
        delete: (then, catcher, id) => {
            del(`/strategy/v1/${id}`, then, catcher)
        },
        backtest:{
            get: (then, catcher, strategy_id, backtest_id) => {
                get(`/strategy/v1/${strategy_id}/backtest/details/${backtest_id}`, then, catcher)
            },
            create: (then, catcher, id, execution) => {
                // {
                //     exchange: "",
                //         pairs: [],
                //     candles: 1000,
                // }
                execution.candles = parseInt(execution.candles)
                post(`/strategy/v1/${id}/backtest`, execution, then, catcher)
            },
            history: (then, catcher, id) => {
                get(`/strategy/v1/${id}/backtest/history`, then, catcher)
            },
        },

        list:{
            get: (then, catcher) => {
                get(`/strategy/v1/list`, then, catcher)
            },
            create: (then, catcher, strategy) => {
                // {
                //     id: "",
                //     alert_id:"",
                //     name:"",
                //     position_type:"",
                //     sl:"",
                //     tp:"",
                //     balance: 0.0,
                //     position:"",
                // }
                strategy.balance = parseFloat(strategy.balance)
                strategy.margin = parseFloat(strategy.margin)
                post(`/strategy/v1/list`, strategy, then, catcher)
            },
        },
    },
    telegrambot: {
        get: (then, catcher) => {
            get(`/telegrambot/v1`, then, catcher)
        },
        register: (then, catcher) => {
            get(`/telegrambot/v1/register`, then, catcher)
        },
        del: (then, catcher, id) => {
            del(`/telegrambot/v1/${id}`, then, catcher)
        },

    },
    twitterbot: {
        ops:{
            get: (then, catcher) => {
                get(`/twitterbot/ops`, then, catcher)
            },
            create: (then, catcher, alertId) => {
                post(`/twitterbot/ops`, {alert_id: alertId}, then, catcher)
            },
            del: (then, catcher, id) => {
                del(`/twitterbot/ops?id=${id}`, then, catcher)
            },
        },
    },
    webpush: {
        config: (then, catcher) => {
            get(`/webpush/v1/config`, then, catcher)
        },
    },



};

function get(url, then, catcher, triedRefresh) {
    axi.get(url).then(then).catch(e => {
        if (e.response && e.response.status === 401 && !triedRefresh) {
            axi.post("/auth/v1/refresh").then(() => {
                get(url, then, catcher, true)
            }).catch(() => {
                document.location = `/login?redirect=${window.location}`;
            })
        } else {
            catcher(e)
        }
    })
}

function post(url, data, then, catcher, triedRefresh) {
    axi.post(url, data).then(then).catch(e => {
        if (e.response && e.response.status === 401 && !triedRefresh) {
            axi.post("/auth/v1/refresh").then(() => {
                post(url, then, catcher, true)
            }).catch(() => {
                document.location = `/login?redirect=${window.location}`;
            })
        } else {
            catcher(e)
        }
    })
}

//
// function put(url, data, then, catcher, triedRefresh) {
//     axi.put(url, data).then(then).catch(e => {
//         if (e.response && e.response.status === 403 && !triedRefresh) {
//             axi.post("/auth/v1/refresh").then(() => {
//                 get(url, then, catcher, true)
//             }).catch(() => {
//                 document.location = '/login';
//             })
//         } else {
//             catcher(e)
//         }
//     })
// }
//
function del(url, then, catcher, triedRefresh) {
    axi.delete(url).then(then).catch(e => {
        if (e.response.status === 401 && !triedRefresh) {
            axi.post("/auth/v1/refresh").then(() => {
                del(url, then, catcher, true)
            }).catch(() => {
                document.location = `/login?redirect=${window.location}`;
            })
        } else {
            catcher(e)
        }
    })
}


export default api