const routeList = [
    {
        path: 'journal',
        name: 'Journal',
        meta: {
            title: 'Journal'
        },
        component: () => import('@/pages/journal/Journal.vue'),
    },
    {
        path: 'journal/create',
        name: 'Create Journal',
        meta: {
            title: 'New Entry'
        },
        component: () => import('@/pages/journal/Edit.vue'),
    },
    {
        path: 'journal/:journalId',
        name: 'Journal View',
        meta: {
            title: 'View Journal Entry'
        },
        component: () => import('@/pages/journal/View.vue'),
    },
    {
        path: 'journal/:journalId/edit',
        name: 'Journal Edit',
        meta: {
            title: 'Edit Entry'
        },
        component: () => import('@/pages/journal/Edit.vue'),
    },
]


export default {
    routes: routeList
}